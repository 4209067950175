import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import AdminService from "../../services/admin.service";
import AccountService from "../../services/account.service";
import { removeTrailingSlash } from "../../helpers/removeTrailingSlash";

const href = window.location.href.split("/");
let url = "";
if (href.length >= 3) {
  for (let i = 0; i < 3; i++) {
    url = url + href[i] + "/";
  }

  // href.forEach((x) => (url = url + x + "/"));
}
const _loginUrl = process.env.REACT_APP_ACCOUNT_LOGIN + url;

const SignUpSSO2 = () => {
  const [iframeSrc,setIframeSrc] = useState(undefined);
  document.body.style.overflow = "hidden";
  const iframeRef = useRef(null);

  const getAllPositions = async () => {
    const response = await AdminService.getPosition();
    return await response.json();
  };

  useEffect(()=>{
    setIframeSrc(process.env.REACT_APP_FORM_IFRAME_BASE_SRC)
  },[]);

  const location = useLocation();
  
  useEffect(() => {
    const handleMessage = async (event) => {
      // Validate message source and type
      if (event.origin === removeTrailingSlash(process.env.REACT_APP_FORM_IFRAME_BASE_SRC) && event.data?.type === "FORM_SUBMIT") {
        const formData = event.data.data;

        const hasEquipments = {
          hasIos: !!formData.intraoralScanner,
          hasCbct: !!formData.cbctScanner,
          hasPrinter: !!formData.printer,
          hasChairsideMill: !!formData.chairsideMill,
          hasCadSoftware: !!formData.cadSoftware,
          Ios: formData.intraoralScanner ?? "",
          Cbct: formData.cbctScanner ?? "",
          Printer: formData.printer ?? "",
          ChairsideMill: formData.chairsideMill ?? "",
          CadSoftware: formData.cadSoftware ?? ""
        }

        const position = await getAllPositions();

        const specialty = {
          generalDentist: formData.specialization === 'General Dentist',
          implantoloegiest: formData.specialization === 'Implantoloegiest',
          orthodontist: formData.specialization === 'Orthodontist',
          prosthodontics: formData.specialization === 'Prosthodontics',
          periodontist: formData.specialization === 'Periodontist',
          endodontist: formData.specialization === 'Endodontist',
          notApplicable: formData.specialization === 'Not applicable',
          others: formData.specialization === 'Others',
        }

        const accountModel = {
          PersonnelInviteId: "00000000-0000-0000-0000-000000000000",
          personnel: {
            Email: location.state.userBasicData.email,
            FirstName: location.state.userBasicData.firstName,
            LastName: location.state.userBasicData.lastName,
            PositionId: position.find((x) => x.description === "Dentist").id,
            Role: "Dentist",
            UtmSource: "",
            UtmMedium: "",
            UtmCampaign: "",
          },
          practices: [{
            name: formData.practiceName,
            specialty: JSON.stringify(specialty),
            address: {
              line1: formData.addressLine1,
              line2: formData.addressLine2,
              city: formData.city,
              state: formData.state,
              country: formData.country,
              postal: formData.postal,
            },
            equipment: JSON.stringify(hasEquipments),
            selectedOptions: [],
            email: location.state.userBasicData.email,
            NumberOfChairsInYourPractice: formData.numberOfChairs,
            specialization: formData.specialization,
            specializationOther: "",
            intraoralScanner: formData.intraoralScanner.toLowerCase().replace(/ /g,'_'),
            cbctScanner: formData.cbctScanner,
            n3dprinter: formData.printer.toLowerCase().replace(/ /g,'_'),
            chairsideMill: formData.chairsideMill,
            cadSoftware: formData.cadSoftware
          }],
          address: {
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            postal: formData.postal,
          },
        }

        const updateAccount = () => {
          AccountService.ssoUpdate(accountModel, location.state.token)
            .then((response) => response.json())
            .then(async () => {
              try {
                await AccountService.logOut();
                window.localStorage.clear();
                window.location.href = _loginUrl;
              } catch(error) {
                console.log('error',error)
                if (iframeRef.current) {
                  iframeRef.current.contentWindow.postMessage(
                    { type: 'FROM_PARENT', value: { success: false } },
                    '*'
                  );
                }
              }
            })
            .catch((err) => {
              console.log("err", err);
              if (iframeRef.current) {
                iframeRef.current.contentWindow.postMessage(
                  { type: 'FROM_PARENT', value: { success: false } },
                  '*'
                );
              }
              // setIsLogin(false);
              // setIsSubmitting(false);
            });

            if (iframeRef.current) {
              iframeRef.current.contentWindow.postMessage(
                { type: 'FROM_PARENT', value: { success: false } },
                '*'
              );
            }
            if (iframeRef.current) {
              iframeRef.current.contentWindow.postMessage(
                { type: 'FROM_PARENT', value: { success: true } },
                '*'
              );
            }
        };
    
        updateAccount();
      }
    };

    // Listen for messages from the iframe
    window.addEventListener("message", handleMessage);

    // Cleanup
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      {iframeSrc && <iframe title="Create Account Clinic" ref={iframeRef} src={`${iframeSrc}create-account-clinic`} style={{width: '100vw', height: '100vh', overflow: 'auto'}}></iframe>}  
    </>
  );
};

export default SignUpSSO2;
